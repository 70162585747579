.homeContaier {
  width: 100%;
  margin-top: -66px;
  overflow-x: hidden;
}

.imageContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 98vh;
  overflow: hidden;
  width: 100vw;
}

.sliderImage {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.sliderImage:hover {
  transform: scale(1.5);
}

.bannerInfo {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.bannerInfo h1 {
  color: white;
  font-size: 5vw;
}

.bannerInfo h3 {
  color: white;
  font-size: 2.5vw;
}

.bannerInfo p {
  color: white;
  font-size: 1.5vw;
  font-style: italic;
}

.verse {
  font-family: "Satisfy", cursive !important;
}
