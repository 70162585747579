.navbar {
    transition: all 0.5s ease-in-out;
background-color: rgba(0, 0, 0, 0.2);
}
.stickyNavbar {
  background-color: rgba(0, 0, 0, 0.8);
}

.navItem {
  color: #fff !important;
  }
