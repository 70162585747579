* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --activeTextColor: #44bbff;
}

body {
  font-family: "Josefin Sans", sans-serif !important;
  background-color: #f4f5f7;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.navbar-toggler {
  background: #f4f5f7 !important;
}

.slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

::-webkit-scrollbar {
  display: none;
}
::-moz-scrollbar {
  display: none !important;
}

.carousel .thumb {
  border-radius: 5px !important;
}
.carousel .thumb img {
  width: 70px !important;
  height: 50px;
  object-fit: cover !important;
  border-radius: 0 !important;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid var(--activeTextColor) !important;
}
.carousel .thumbs-wrapper .control-prev.control-arrow {
  left: -50px !important;
}
.carousel .thumbs-wrapper .control-next.control-arrow {
  right: -50px !important;
}
